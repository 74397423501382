
import { defineComponent, ref } from 'vue';

import { auth } from '@/firebase';
import { sendPasswordResetEmail } from 'firebase/auth';

// Don't need the whole AuthExecutor flow, just need the fallback error handler,
// since all we're doing is sending a single email
import { fallbackHandler } from '@/auth-hooks';

export default defineComponent({
  name: 'ResetPasswordForm',
  setup() {
    const email = ref("");
    const errors = ref<string[]>([]);
    const success = ref(false);

    const submit = async () => {
      errors.value = [];

      if (email.value.length == 0) {
        errors.value.push("Please enter an email address.");
        return;
      }

      if (success.value) {
        errors.value.push("An email has already been sent.")
        return;
      }

      try {
        await sendPasswordResetEmail(auth, email.value);
        success.value = true;
      } catch (error) {
        fallbackHandler(error);
      }
    }

    return { email, errors, success, submit };
  }
});
