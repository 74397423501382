
import { defineComponent, inject, ref } from 'vue';

import { reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';

import { useAuthFlow, useThirdPartyAuth } from '@/auth-hooks';
import { ModalPayloadKey, UserDataKey } from '../types';

import PasswordField from '@/components/PasswordField.vue';


export default defineComponent({
  components: { PasswordField },
  setup() {
    const { user, hasEmail, hasGoogle } = inject(UserDataKey)!;
    const modalPayload = inject(ModalPayloadKey)!;

    const email = ref("");
    const password = ref("");

    const errors = ref<string[]>([]);

    const { authExecutor } = useAuthFlow({ errors });
    const { reauthenticate } = useThirdPartyAuth();

    const validate = () => {
      errors.value = [];

      if (email.value.length == 0)
        errors.value.push("Please enter an email address");

      if (password.value.length == 0)
        errors.value.push("Please enter a password.");

      return errors.value.length == 0;
    }

    const submit = async () => {
      if (!validate()) return;

      const cred = EmailAuthProvider.credential(email.value, password.value);

      const success = await authExecutor(reauthenticateWithCredential(
        user.value,
        cred
      ));

      if (success) {
        if (modalPayload.value?.callback) modalPayload.value.callback();
        else modalPayload.value = null;
      }
    }

    const googleSubmit = async () => {
      const success = await authExecutor(reauthenticate());
      if (success) {
        if (modalPayload.value?.callback) modalPayload.value.callback();
        else modalPayload.value = null;
      }
    }

    return {
      hasEmail, hasGoogle, email, password, submit, googleSubmit, errors,
      modalPayload
    };
  }
});
