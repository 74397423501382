
import { computed, defineComponent, PropType, ref } from 'vue';

import EyeOpen from '@/assets/icons/eye-open.svg';
import EyeClosed from '@/assets/icons/eye-closed.svg';

export default defineComponent({
  name: 'PasswordField',
  props: {
    // v-model value
    value: { type: String as PropType<string> },
    // Optional v-model hidden -- if passed, will model this variable for
    // whether or not to be hidden; otherwise, will just use its own state
    hidden: {
      type: Boolean as PropType<boolean> | undefined as PropType<undefined>,
      default: undefined
    }
  },
  emits: [ 'toggle', 'update:value', 'update:hidden' ],
  setup(props, { emit }) {
    const propPassed = props.hidden !== undefined;

    // Internal state, used only when prop is not passed: undefined otherwise
    const _hidden = ref<boolean | undefined>(!propPassed ? true : undefined);

    /**
     * Wrapper for alternative 'hidden' behaviour: if props.hidden is passed,
     * then this will return its value on get and emit 'update:hidden' on set.
     * Otherwise, it will proxy to the internal _hidden state.
     */
    const hidden = computed({
      get: () => {
        if (!propPassed) return _hidden.value;
        else return props.hidden;
      },
      set: value => {
        if (!propPassed) _hidden.value = value;
        else emit('update:hidden', value);
      }
    });

    const icon = computed(() => hidden.value ? EyeClosed : EyeOpen);

    const onClick = () => hidden.value = !hidden.value;
    const onInput = (event: InputEvent) => {
      emit('update:value', (event.target as HTMLInputElement).value);
    }

    return { icon, hidden, onInput, onClick };
  }
});
