
import { defineComponent, ref } from 'vue';

import { auth } from '@/firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';

import router from '@/router';
import PasswordField from '@/components/PasswordField.vue';
import { useAuthFlow, useThirdPartyAuth } from '@/auth-hooks';

export default defineComponent({
  name: 'LoginForm',
  components: { PasswordField },
  setup() {
    const email = ref("");
    const password = ref("");
    const errors = ref<string[]>([]);

    const { signIn: googleSignIn } = useThirdPartyAuth();
    const { authExecutor, handleRedirection } = useAuthFlow({ errors });

    const validate = () => {
      errors.value = [];

      if (email.value.length == 0)
        errors.value.push("Please enter an email address.");

      if (password.value.length == 0)
        errors.value.push("Please enter a password.");

      return errors.value.length == 0;
    }

    const submit = async () => {
      if (!validate()) return;

      const success = await authExecutor(signInWithEmailAndPassword(
        auth,
        email.value,
        password.value
      ));

      if (success) await router.push({ name: 'Home' });
    }

    const googleSubmit = async () => {
      const success = await authExecutor(googleSignIn());
      if (success) await router.push({ name: 'Home' });
    }

    handleRedirection().then(success => {
      if (success === true) router.replace({ name: 'Home' });
    });

    return { email, password, errors, submit, googleSubmit };
  }
});
