
import {
  defineComponent, ref, reactive, computed, ComputedRef, inject
} from 'vue';

import {
  updateEmail, updatePassword, reauthenticateWithCredential,
  sendEmailVerification, EmailAuthProvider
} from 'firebase/auth';

import { useAuthFlow } from '@/auth-hooks';
import { errorHandler } from '../recent-handler';
import { UserDataKey } from '../types';

import PasswordField from '@/components/PasswordField.vue';


function useChangeEmailForm(providerEmail: ComputedRef<string>) {
  const { user, refreshUser } = inject(UserDataKey)!;

  const visible = ref(false);
  const newEmail = ref("");
  const password = ref("");

  const errors = ref<string[]>([]);

  const { authExecutor } = useAuthFlow({ errors, errorHandler });

  const validate = () => {
    errors.value = [];

    if (newEmail.value.length == 0)
      errors.value.push("Please enter a new email address.");
    else if (password.value.length == 0)
      errors.value.push("Please enter your password.");

    return errors.value.length == 0;
  }

  const close = () => {
    newEmail.value = "";
    visible.value = false;
  }

  const submit = () => {
    if (!validate()) return;

    const execute = async () => {
      const success = await authExecutor(updateEmail(
        user.value,
        newEmail.value
      ));

      if (success) {
        await sendEmailVerification(user.value);
        refreshUser();
        close();
      }
    }

    execute().catch(async () => {
      const cred = EmailAuthProvider.credential(
        providerEmail.value,
        password.value
      );

      const success = await authExecutor(reauthenticateWithCredential(
        user.value,
        cred
      ));

      if (success) await execute();
    });
  }

  return {
    emailForm: reactive({
      open, visible, newEmail, password, errors, submit, close
    })
  };
}


function useChangePasswordForm(providerEmail: ComputedRef<string>) {
  const { user, refreshUser } = inject(UserDataKey)!;

  const visible = ref(false);

  const oldPassword = ref("");
  const password1 = ref("");
  const password2 = ref("");
  const hiddenFields = ref(true);

  const errors = ref<string[]>([]);

  const { authExecutor } = useAuthFlow({ errors, errorHandler });

  const validate = () => {
    errors.value = [];

    if (oldPassword.value.length == 0)
      errors.value.push("Please enter your current password.");
    else if (password1.value.length == 0)
      errors.value.push("Please enter a password.");
    else if (password2.value.length == 0)
      errors.value.push("Please verify your password.");
    else if (password1.value != password2.value)
      errors.value.push("Those passwords do not match.");

    return errors.value.length == 0;
  }

  const close = () => {
    password1.value = "";
    password2.value = "";
    visible.value = false;
  }

  const submit = () => {
    if (!validate()) return;

    const execute = async () => {
      const success = await authExecutor(updatePassword(
        user.value,
        password1.value
      ));

      if (success) {
        refreshUser();
        close();
      }
    }

    execute().catch(async () => {
      const cred = EmailAuthProvider.credential(
        providerEmail.value,
        oldPassword.value
      );

      const success = await authExecutor(reauthenticateWithCredential(
        user.value,
        cred
      ));

      if (success) await execute();
    });
  }

  return {
    passwordForm: reactive({
      open, visible, oldPassword, password1, password2, hiddenFields, errors,
      submit, close
    })
  };
}


export default defineComponent({
  components: { PasswordField },
  setup() {
    const { user } = inject(UserDataKey)!;

    const providerEmail = computed(() => {
      // '!' because this component is only shown when 'hasEmail' is true, and
      // because the 'password' provider always has an email attached to it (the
      // other providers may not, hence 'string | null').
      return user.value.providerData
        .find(p => p!.providerId == 'password')!
        .email!;
    });

    return {
      providerEmail,
      ...useChangeEmailForm(providerEmail),
      ...useChangePasswordForm(providerEmail)
    };
  }
});
