
import { defineComponent, ref, Ref, computed, provide } from 'vue';

import { auth } from '@/firebase';
import { signOut, User } from 'firebase/auth';

import router from '@/router';
import { useAuthFlow } from '@/auth-hooks';

import {
  ModalPayload, ModalReason, ModalPayloadKey, UserDataKey
} from './types';

import ConfirmModal from '@/components/ConfirmModal.vue';

import TheReauthForm from './components/TheReauthForm.vue';
import TheEmailPassword from './components/TheEmailPassword.vue';
import TheSignInMethods from './components/TheSignInMethods.vue';
import TheDangerZone from './components/TheDangerZone.vue';
import TheLinkForm from './components/TheLinkForm.vue';

import '@/assets/styles/forms.scss';


function useUserData() {

  const user: Ref<User> = ref(auth.currentUser!);

  const refreshUser = () => {

    /**
     * @note
     * In Vue 2 (with options API, of course), we used
     * ```
     * this.$set(this.user, firebase.auth().currentUser)
     * ```
     * to do this. The $set is supposedly no longer necessary now that we have
     * Proxies, but there's something broken. "Refreshing" the ref's properties
     * by re-assigning it doesn't seem to work as expected. Perhaps this is
     * intended, and is documented somewhere? I would like to see where. To get
     * around it, we set it to 'null' for a brief second so we can re-assign all
     * the properties and trigger our computed and template refreshes.
     *
     * This is probably a bug, if I had to guess.
     */

    // @ts-ignore to unset the value temporarily and trigger a real re-render
    user.value = null;
    user.value = auth.currentUser!;
  }

  const hasEmail = computed(() => {
    return user.value.providerData.some(p => p?.providerId == 'password');
  });

  const hasGoogle = computed(() => {
    return user.value.providerData.some(p => p?.providerId == 'google.com');
  });

  provide(UserDataKey, { user, hasEmail, hasGoogle, refreshUser });

  return { user, hasEmail, hasGoogle };
}


export default defineComponent({
  name: 'Account',
  components: {
    ConfirmModal, TheReauthForm, TheLinkForm,
    TheEmailPassword, TheSignInMethods, TheDangerZone
  },
  setup() {
    const errors = ref<string[]>([]);
    const { authExecutor } = useAuthFlow({ errors });

    const modalPayload = ref<ModalPayload | null>(null);

    provide(ModalPayloadKey, modalPayload);

    const onSignOut = async () => {
      const success = await authExecutor(signOut(auth));
      if (success) await router.push({ name: 'Home' });
    }

    const noButtonModal = computed(() => {
      const reason = modalPayload.value?.reason;
      return (
        reason == ModalReason.Reauthorize ||
        reason == ModalReason.LinkEmailPassword
      );
    });

    const modalConfirm = () => modalPayload.value?.callback?.();

    return {
      ...useUserData(),
      onSignOut, errors,
      modalPayload, modalConfirm, noButtonModal, ModalReason
    };
  }
});
