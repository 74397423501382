
import { defineComponent, PropType } from 'vue';

import '@/assets/styles/forms.scss';

export default defineComponent({
  name: 'Auth',
  props: {
    formID: { type: String as PropType<string>, required: true }
  }
});
