
import { defineComponent, ref } from 'vue';

import { auth } from '@/firebase';
import {
  createUserWithEmailAndPassword, sendEmailVerification, UserCredential
} from 'firebase/auth';

import router from '@/router';
import PasswordField from '@/components/PasswordField.vue';
import { useAuthFlow, useThirdPartyAuth } from '@/auth-hooks';

export default defineComponent({
  name: 'RegisterForm',
  components: { PasswordField },
  setup() {
    const email = ref("");
    const password1 = ref("");
    const password2 = ref("");
    const errors = ref<string[]>([]);

    const hidden = ref(true);

    const { signIn: googleSignIn } = useThirdPartyAuth();
    const { authExecutor, handleRedirection } = useAuthFlow({ errors });

    const validate = () => {
      errors.value = [];

      if (email.value.length == 0)
        errors.value.push("Please enter an email address.");

      if (password1.value.length == 0)
        errors.value.push("Please enter a password.");
      else if (password2.value.length == 0)
        errors.value.push("Please verify your password.");
      else if (password1.value != password2.value)
        errors.value.push("Those passwords do not match.");

      return errors.value.length == 0;
    }

    const submit = async () => {
      if (!validate()) return;

      const newUserCred = ref<UserCredential>();

      const success = await authExecutor(
        createUserWithEmailAndPassword(auth, email.value, password1.value),
        newUserCred
      );

      if (success) {
        await sendEmailVerification(newUserCred.value!.user);
        await router.push({ name: 'Home' });
      }
    }

    const googleSubmit = async () => {
      const success = await authExecutor(googleSignIn());
      if (success) await router.push({ name: 'Home' });
    }

    handleRedirection().then(success => {
      if (success === true) router.replace({ name: 'Home' });
    });

    return {
      email, password1, password2, errors,
      hidden, submit, googleSubmit
    };
  }
});
